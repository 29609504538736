@import "_reset";
@import "_variables";
@import "_grid";
@import "_header";
@import "_banner";
@import "_what-we-do";
@import "_our-team";
@import "_footer";

body {
  box-sizing: border-box;
  background: $black;
  font-family: 'Montserrat', sans-serif;
  font-size: 18px;
  color: $gray;
  position: relative;
  overflow-x: hidden;
}

.align-middle {
  display: flex;
  align-items: center;
}

.read-more-link {
  color: $green;
  text-decoration: none;

  &__arrow {
    width: 12px;
    margin-left: 5px;
  }
}

p {
  line-height: 30px;
}

.fader {
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  background: rgba(188,188,188, .3);
  display: none;
}

.push .fader {
  display: block;
}

.burger-menu {
  display: none;
  position: relative;
  width: 50px;
  height: 38px;
  cursor: pointer;
}
.burger-menu__line{
  position: absolute;
  background:#ffff;
  width: 100%;
  height: 4px;
  top: 50%;
  right: 0px;
  margin-top: -5px;
  opacity: 1;
}
.burger-menu__line::before {
  position: absolute;
  background:#ffff;
  width: 50px;
  height: 4px;
  top: 14px;
  content: "";
  display: block;
}
.burger-menu__line::after {
  position: absolute;
  background:#ffff;
  width: 50px;
  height: 4px;
  bottom: 14px;
  content: "";
  display: block;
}
.burger-menu__line::after, .burger-menu__line::before, .burger-menu__line  {
  transition: all .3s ease-in-out;
  -webkit-transition: all .3s ease-in-out;
}
.push .burger-menu__line::after{
   transform: rotate(-45deg);
   -webkit-transform: rotate(-45deg);
  bottom: 0px;
}
.push .burger-menu__line::before{
   transform: rotate(45deg);
   -webkit-transform: rotate(45deg);
  top: 0px;
}
.push .burger-menu__line {
 background: rgba(111,111,111,.0);
}

@media (max-width: 992px) {
  .burger-menu { 
    display: block;
    z-index: 1;
  }
}