.banner {
  position: relative;

  &__bkg {
    width: 57%;
    position: absolute;
    top: 65px;
    right: 0;
    z-index: -1;
  
    img {
      width: 100%;
    }
  }

  &__info {
    padding-top: 245px;
    max-width: 460px;
  }

  &__title {
    font-weight: 700;
    font-size: 40px;
    line-height: 50px;
    padding-bottom: 45px;
    color: $white;

    &_green {
      color: $green;
    }
  }

  &__text {
    font-size: 18px;
    line-height: 30px;
  }
}

@media (max-width: 1480px) {
  .banner__info {
    padding-top: 100px;
  }
}


@media (max-width: 992px) {
  .banner__info {
    padding-top: 100px;
  }
}

@media (max-width: 768px) {
  .banner{
    &__bkg {
      position: static;
      width: 100%;
    }

    &__info {
      padding-top: 0px;
    }
  }
}