.header {
  &__inner {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 30px 0;
  }

  &__logo {
    img {
      width:146px;
    }
  }

  &__menu {
    position: relative;
  }

  &__menu-inner {
    display: flex;
  }

  &__menu-item {
    margin-right: 60px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__link {
    text-decoration: none;
    color: $gray;

    &_active {
      color: $green;
    }
  }
}

@media (max-width: 1480px) {
  .header {
    &__logo {
      img {
        width:126px;
      }
    }
  }
}

@media (max-width: 1200px) {
  .header {
    &__menu-item {
      margin-right: 30px;
    }
  }
}

@media (max-width: 992px) {
  .header {

    &__menu {
      z-index: 2;
    }

    &__menu-inner {
      display: none;
      position: absolute;
      background: #2c2c30;
      padding: 60px 20px 20px 20px;
      right: -20px;
      top: -20px;
      min-width: 226px;
    }

    &__menu-item {
      margin-right: 0;
      padding-bottom: 20px;
    }
  }

  .push .header__menu-inner {
    display: block;
  }

}