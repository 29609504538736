.footer {

  &__inner {
    width: 100%;
    padding: 30px 60px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;

  }

  &__logo {
    img {
      width:146px;
    }
  }

  &__menu {
    display: flex;
  }

  &__menu-item {
    margin-right: 60px;

    &:last-child {
      margin-right: 0;
    }
  }

  &__link {
    text-decoration: none;
    color: $gray;

    &_active {
      color: $green;
    }
  }

  &__socials {
    display: flex;
  }

  &__social-link {
    width: 39px;
    display: block;

    &:first-child {
      margin-right: 20px;
    }

    img {
      width: 100%;
    }
  }

  &__contact-us {
    color: $green;
    text-decoration:none;
  }

  &__copyright {
    position: absolute;
    right: 60px;
    bottom: 5px;
    text-align: right;
    font-size: 12px;
  }
}

@media (max-width: 1480px) {
  .footer {
    &__logo {
      img {
        width:126px;
      }
    }
  }
}

@media (max-width: 1200px) {
  .footer {

    &__menu-item {
      margin-right: 30px;
    }

    &__social-link {
      width: 29px;

      &:first-child {
        margin-right: 10px;
      }
    }
  }
}

@media (max-width: 992px) {
  .footer {
    &__inner {
      padding: 30px 0;
      flex-direction: column;
      justify-content : center;
    }

    &__logo {
      display: none;
    }

    &__socials {
      margin-top: 20px;
    }

    &__copyright {
      position: static;
    }
  }
}

@media (max-width: 768px) {
  .footer {
    &__menu-item {
      margin-right: 20px;
    }
  }
}

@media (max-width: 460px) {
  .footer {
    &__menu-item {
      font-size: 12px;
    }

    &__socials {
      font-size: 12px;
    }
  }
}