.our-team {
  background: url(../images/our-team-bg.png);
  padding: 176px 0;
  background-repeat: no-repeat;
  background-size: cover;

  &__inner {
    display: flex;
    justify-content: center;
  }

  &__info {
    max-width: 680px;
    text-align: center;
  }

  &__title {
    font-weight: 700;
    font-size: 40px;
    padding-bottom: 36px;
    color: $white;

    &_green {
      color: $green;
    }
  }

  &__describtion {
    padding-bottom: 35px;
    color: $white;
  }
}

@media (max-width: 1200px) {
  .our-team {
    padding: 100px 0;
  }
}