.what-we-do {
  margin-top: 315px;
  &__title {
    text-align: center;
    font-size: 40px;
    font-weight: 700;
    color: $white;
    margin-bottom: 115px;

    &_green {
      color: $green;
    }
  }

  &__item-title {
    font-size: 35px;
    font-weight: bold;
    color: $green;
    margin-bottom: 38px;
  }

  &__item-text {
    line-height: 30px;
    font-size: 18px;
    margin-bottom: 38px;
    max-width: 600px
  }

  &__item-img {
    width: 500px;

    .item-img {
      width: 100%;
    }
  }

  .item {
    margin-bottom: 85px;
  }

  .pattern-container {
    position: relative;
  }
  .pattern {
    position: absolute;
    z-index: -1;
  }

  .pattern_01 {
    top: -80px;
    right: -80px;
  }

  .pattern_02 {
    bottom: -80px;
    right: -80px;
  }

  .pattern_03 {
    bottom: -120px;
    right: 20px;
  }
}

@media (max-width: 1345px) {
  .what-we-do {
    margin-top: 170px;

    &__title {
      margin-bottom: 100px;
    }
    
    &__item-img {
      width: 400px;
    }

    .pattern_01 {
      width: 140px;
    }
  }
}


@media (max-width: 1200px) {
  .what-we-do {
    margin-top: 105px;

    &__item-img {
      width: 350px;
    }

    .pattern_01 {
      width: 100px;
      top: -65px;
      right: -44px;
    }

    .pattern_02 {
      width: 100px;
      bottom: -55px;
      right: -10px;
    }
  }
}

@media (max-width: 992px) {
  .what-we-do {
    .pattern {
      display: none;
    }
  }
}

@media (max-width: 768px) {
  .what-we-do {
    margin-top: 0;
    &__item-img {
      padding: 0 20px;
      width: 100%;
    }

    .item {
      margin-bottom: 38px;
    }
  }
}